import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import DataTable from 'components/Table/Table.js';
import ModalPedidosSQL from 'components/Pedidos/ModalPedidosSQL';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import axios from 'axios';
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Form, ButtonStyled } from './styles';
import Autocomplete from 'react-autocomplete';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Pesquisa } from './styles';
import Pdf from '../../components/Pedidos/PDF';
import NotasOperador from '../../components/Pedidos/NotasOperador';
import SendEmail from '../../components/Pedidos/ModalEmail';
import moment from 'moment';
import { API, ERP_CONF } from '../../config/api';
import Paper from '@material-ui/core/Paper';
import Async from 'react-select/async';
import debounce from 'debounce-promise';
import { TablePagination, Divider } from '@material-ui/core';
import Select from 'react-select';
import translate from 'components/Tradutor/tradutor';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  badgeContainer: {
    display: 'flex',
    gap: '1px',
    justifyContent: 'center', // Centraliza os badges horizontalmente
    marginBottom: '8px', // Define a margem inferior dos badges
  },
}));

function createData(
  actions,
  numeroSistema,
  empresa,
  status,
  nomeConta,
  emissao,
  disponibilidade,
  tags,
  vendedor,
  valor,
) {
  return {
    actions,
    numeroSistema,
    empresa,
    status,
    nomeConta,
    emissao,
    disponibilidade,
    tags,
    vendedor,
    valor,
  };
}

const rowHead = [
  {
    title: 'Ações',
    field: 'actions',
    headerStyle: {
      width: 60,
      maxWidth: 60,
      textAlign: 'center',
    },
    cellStyle: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
      textAlign: 'center',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f0f0f0', // Definindo a cor de fundo
    },
    render: rowData => (
      <div style={{ display: 'flex', gap: '0px' }}>{rowData.actions}</div>
    ),
  },

  {
    title: 'Pedido',
    field: 'numeroSistema',
    headerStyle: {
      width: 60,
      whiteSpace: 'nowrap',
      maxWidth: 60,
    },
    cellStyle: {
      width: 60,
      maxWidth: 60,
      whiteSpace: 'nowrap',
      textAlign: 'left',
      fontSize: '12px',
    },
  },
  {
    title: 'Empresa',
    field: 'empresa',
    headerStyle: {
      width: 80,
      whiteSpace: 'nowrap',
      maxWidth: 80,
    },
    cellStyle: {
      width: 80,
      maxWidth: 80,
      whiteSpace: 'nowrap',
      textAlign: 'left',
      fontSize: '12px',
    },
  },

  {
    title: 'Status',
    field: 'status',
    cellStyle: {
      fontSize: '12px',
    },
  },

  {
    title: 'Cliente',
    field: 'nomeConta',
    cellStyle: {
      fontSize: '5px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      fontSize: '12px',
    },
  },
  {
    title: 'Emissão',
    field: 'emissao',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Disponibilidade',
    field: 'disponibilidade',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Tags',
    field: 'tags',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Vendedor',
    field: 'vendedor',
    headerStyle: {
      width: 450,

      maxWidth: 450,
    },
    cellStyle: {
      width: 450,
      minWidth: 300,

      textAlign: 'left',
      fontSize: '12px',
    },
  },

  {
    title: 'Pedido Valor',
    field: 'valor',

    headerStyle: {
      width: 100,
      whiteSpace: 'nowrap',
      textAlign: 'right',
      maxWidth: 100,
    },
    cellStyle: {
      width: 120,
      maxWidth: 120,
      whiteSpace: 'nowrap',
      textAlign: 'right',
      fontSize: '12px',
    },
  },
];

function Pedidos() {
  const classes = useStyles();
  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const perfil = sessionStorage.getItem('perfil');
  const id_user = sessionStorage.getItem('id');
  //  const clientes = JSON.parse(sessionStorage.getItem('clientes'));
  const [PanelOpen, setPanelOpen] = React.useState(false);
  const { register, getValues } = useForm();

  // AutoComplete
  const [auto, setAuto] = useState([]);
  const [dadosPedidos, setDadosPedidos] = useState();
  const [statusPedidos, setStatusPedidos] = useState([]);
  const [vendedorP, setVendedorP] = useState('');
  const [idVendedorP, setIdVendedorP] = useState('');

  const [autoNomeSupervisor, setAutoNomeSupervisor] = useState('');
  const [idSupervisor, setIdSupervisor] = useState('');
  const [autoListSupervisor, setAutoListSupervisor] = useState([]);

  let value = '';
  let valueId = '';

  const [valueAutoId, setValueAutoId] = useState(valueId);
  const [valueAutoNome, setValueAutoNome] = useState(value);
  const [autoCliente, setAutoCliente] = useState();
  const [nomeCliente, setNomeCliente] = useState('');
  const [idCliente, setIdCliente] = useState('');

  const [totalPedidos, settotalPedidos] = useState(0);
  const [loading, setLloading] = useState(false);
  const [idProduto, setIdProduto] = useState('');
  const [idProdutoMestre, setIdProdutoMestre] = useState('');

  const [autoVendedor, setAutoVendedor] = useState([]);
  const [fieldsReadonly, setfieldsReadonly] = useState(false);
  const [status_options, setStatus_options] = useState();

  var data = new Date();
  var hoje = data
    .toLocaleDateString()
    .substr(0, 10)
    .split('/')
    .reverse()
    .join('-');
  const loadStatusPedidos = async (inputValue, callback) => {
    try {
      const response = await axios.get(`${API.statuspedidos}`, {
        headers: {
          'x-access-token': token,
        },
      });
      const status = response.data.data.map(item => {
        return {
          value: item.code,
          label: translate(item.code),
        };
      });
      setStatus_options(status);

      //      setStatusPedidos(status);
    } catch (error) {
      if (error.response && error.response.status === 402) {
        //token expirado
        toast.error('Sua sessão expirou, favor efetuar login');
        sessionStorage.clear();
      } else {
        toast.error('Erro ao carregar ');
      }
    }
  };

  const loadClients = async (inputValue, callback) => {
    try {
      var busca = encodeURIComponent(inputValue);

      var where = `&concat_cliente='*${busca}*'`;

      const response = await axios.get(
        `${API.clientes}?email=${email}${where}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );

      //função que troca null por "" para evitar erro em tela para objeto nulo .
      var k = '';
      var v = ';';
      for (const obj of response.data.data) {
        if (typeof obj !== 'object') continue;
        for (k in obj) {
          if (!obj.hasOwnProperty(k)) continue;
          v = obj[k];
          if (v === null || v === undefined) {
            obj[k] = '';
          }
        }
      }

      const data = response.data.data.map(item => {
        return {
          value: item.id,
          label: (
            <span
              dangerouslySetInnerHTML={{
                __html:
                  '<strong>Id:</strong> ' +
                  item.id +
                  '<br> <strong>Nome/Apelido:</strong>' +
                  item.name +
                  '<br><strong>CNPJ/CPF:</strong>' +
                  item.documentNumber +
                  '<br><strong>Cidade:</strong>' +
                  item.city.name +
                  '/' +
                  item.city.state.name +
                  '<hr>',
              }}
            />
          ),
          labelshow: item.id + ' ' + item.name + ' ' + item.documentNumber,
          dados: item,
        };
      });
      // console.log(data);
      return data;
    } catch (err) {}
  };
  const loadProdutosMestre = async (inputValue, callback) => {
    try {
      const response = await axios.get(
        `${API.produtos_NameOrCod}?tipo=filho&pesquisa=${inputValue}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      var reduced = [];
      response.data.data.forEach(item => {
        var duplicated =
          reduced.findIndex(y => {
            return item.product.id + item.product.code == y.product.id + y.product.code;
          }) > -1;

        if (!duplicated) {
          reduced.push(item);
        }
      });

      const data = reduced
      .map(item => {
        return {
          value: item.product.id,
          label:
            item.product.code +
            ' - ' +
            (item.product.description ? item.product.description : '') +
            ' - ' +
            (item.product.id ),

        };
      })
      .sort((a, b) => {
        return a.value > b.value ? 1 : b.value > a.value ? -1 : 0;
      });
    

      // console.log(data);
      return data;
    } catch (err) {
      //              toast.error("Não encontrado");
      setLloading(false);
    }
  };
  const loadProdutosGrade = async (inputValue, callback) => {
    try {
      const response = await axios.get(
        `${API.produtos_NameOrCod}?tipo=grade&pesquisa=${inputValue}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      var reduced = [];
      response.data.data.forEach(item => {
        var duplicated =
          reduced.findIndex(y => {
            return item.id + item.code == y.id + y.code;
          }) > -1;

        if (!duplicated) {
          reduced.push(item);
        }
      });

      const data = reduced
        .map(item => {
          return {
            value: item.id,
            label:
            item.code +
            ' - ' +
            (item.product.description ? item.product.description : '') +
            ' - ' +
            (item.complement ? item.complement : item.variant ? item.variant.description : ''),
        };
        })
        .sort((a, b) => {
          return a.value > b.value ? 1 : b.value > a.value ? -1 : 0;
        });

      // console.log(data);
      return data;
    } catch (err) {
      //              toast.error("Não encontrado");
      setLloading(false);
    }
  };

  //DEBOUNCE CLIENTES
  const loadOptionsClientes = (inputValue, callback) =>
    loadClients(inputValue, callback);

  const debouncedLoadOptionsClientes = debounce(loadOptionsClientes, 3000, {
    leading: false,
  });
  //DEBOUNCE PRODUTOS
  const loadOptionsProdutosMestre = (inputValue, callback) =>
    loadProdutosMestre(inputValue, callback);


  const loadOptionsProdutosGrade = (inputValue, callback) =>
    loadProdutosGrade(inputValue, callback);

  const debouncedLoadOptionsProdutos = debounce(loadOptionsProdutosMestre, 3000, {
    leading: false,
  });

  const debouncedLoadOptionsProdutosGrade = debounce(loadOptionsProdutosGrade, 3000, {
    leading: false,
  });


  useEffect(() => {
    async function handleCleanSup() {
      setIdSupervisor('');
    }

    if (autoNomeSupervisor.length === 0 && perfil !== 'vendedor') {
      handleCleanSup();
    }
  }, [autoNomeSupervisor]);

  useEffect(() => {
    async function handleCleanVendedor() {
      setValueAutoId('');
    }

    if (valueAutoNome.length === 0) {
      handleCleanVendedor();
    }
  }, [valueAutoNome]);

  useEffect(() => {
    const loadSales = async () => {
      try {
        const response = await axios.get(`${API.vendedores}?email=${email}`, {
          headers: {
            'x-access-token': token,
          },
        });
        if (perfil === 'vendedor' && response.data.data[0]) {
          if (response.data.data[0].id) {
            setIdVendedorP(response.data.data[0].id);
            setVendedorP(response.data.data[0].name);
            setfieldsReadonly(true);
          }
        }

        const data = response.data
          ? response.data.data.map(item => {
              return { value: item.id, label: item.name.toUpperCase() };
            })
          : '';

        setAutoVendedor(data);
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error('Sua sessão expirou, favor efetuar o login');
        } else {
          toast.error('Erro ao carregar lista de vendedores');
        }
      }
    };

    const loadSupervisors = async () => {
      try {
        const response = await axios.get(`${API.usuarios}`, {
          headers: {
            'x-access-token': token,
          },
        });

        const data = response.data.data;

        if (perfil !== 'vendedor' && perfil !== 'supervisor') {
          setAutoListSupervisor(
            data.filter(function(obj) {
              return obj.USUARIO_PERFIL === 'supervisor';
            }),
          );
        } else if (perfil === 'supervisor') {
          setAutoListSupervisor(
            data.filter(function(obj) {
              return obj.USUARIO_ID == id_user;
            }),
          );
        } else {
          const id_sup = data.filter(function(obj) {
            return obj.USUARIO_ID == id_user;
          });

          if (id_sup[0] && id_sup[0].USUARIO_CONTA_SUPERVISOR_ID) {
            setAutoListSupervisor(
              data.filter(function(obj) {
                return obj.USUARIO_ID == id_sup[0].USUARIO_CONTA_SUPERVISOR_ID;
              }),
            );
          }
        }
      } catch (error) {
        toast.error('Erro ao carregar lista.');
      }
    };

    async function getOrdersDaily() {
      try {
        setLloading(true);

        var where = `&dt_inicial='${hoje}'&dt_final='${hoje}'`; //ge=data inicial le=data final

        try {
          const response = await axios.get(
            `${API.pedidos}?email=${email}${where}`,
            {
              headers: {
                'x-access-token': token,
              },
            },
          );
          const listPedidos = response.data ? response.data : '';
          /*
          var k = '';
          var v = ';';
          for (const obj of listPedidos) {
            if (typeof obj !== 'object') continue;
            for (k in obj) {
              if (!obj.hasOwnProperty(k)) continue;
              v = obj[k];
              if (v === null || v === undefined) {
                obj[k] = '';
              }
            }
          }

          setDadosPedidos(listPedidos);
          const total = listPedidos
            ? listPedidos.reduce(
                (total, listPedidos) => total + listPedidos.PEDIDO_VALOR,
                0,
              )
            : 0;
          settotalPedidos(total);
*/
          setLloading(false);
        } catch (err) {
          setLloading(false);
          if (err.response.status === 402) {
            //token expirado
            sessionStorage.clear();
          } else {
          }
        }
      } catch (error) {
        setLloading(false);
        toast.error('Não localizado, verifique os campos de pesquisa.');
      }
    }
    loadStatusPedidos();
    loadSales();
    loadSupervisors();
    // getOrdersDaily();
  }, []);

  const Pesquisaitem = (data, e) => {
    e.preventDefault();
    setPanelOpen(false);
    handleSearch(data);
  };

  const handleSearch = async data => {
    try {
      setLloading(true);
      if ((data.dataini == '' || data.datafim == '') && data.numero === '') {
        toast.error('Campo data Inicial e final são obrigatórios.');
        setLloading(false);
      } else {

        toast.success('Pesquisando');
        var where = '';
        where = where + `&perfil=${perfil}`;

        if (data.dataini != '' && data.datafim != '') {
          let dataFinal = new Date(data.datafim);

          // Adicionando 1 dia à data
          dataFinal.setDate(dataFinal.getDate() + 1);

          // Converta de volta para string no formato "YYYY-MM-DD"
          let novaDataFinal = dataFinal.toISOString().split('T')[0];

          where =
            where + `&DATE_START=${data.dataini}&DATE_END=${novaDataFinal}`;
        }

        if (data.numero !== '') {
          where = where + `&SALE_ID=${data.numero}`;
        }

        if (idCliente > 0) {
          where = where + `&PERSON_IDS=${idCliente}`;
        }

        if (idProduto > 0) {
          where = where + `&PRODUCT_PACKING_IDS=${idProduto}`;
        }

        if (idProdutoMestre > 0) {
          
          where = where + `&PRODUCT_IDS=${idProdutoMestre}`;
        }
        if (idVendedorP.length > 0) {
          const vendedores_selecionados = encodeURIComponent(
            JSON.stringify(
              idVendedorP?.map(item => {
                return item.value;
              }),
            ),
          );
          where = where + `&SALESPERSON_IDS=${vendedores_selecionados}`;
        }

        if (data.tags.length > 0) {
          where = where + `&TAG_LIST=${encodeURIComponent(data.tags)}`;
        }
        try {
          const response = await axios.get(
            `${API.pedidos}?email=${email}${where}`,
            {
              headers: {
                'x-access-token': token,
              },
            },
          );

          //const listPedidos = response.data.data;
          //const pedidos = response.data.data.map(item => item )
               response.data.data.forEach(item => {
                 // Verifica se a propriedade 'items' existe e não está vazia
               
                   // Se não houver items, define 'total' como 0
                   item.PEDIDO_VALOR = item.PEDIDO_VALOR>0?item.PEDIDO_VALOR:0;
                 
               });
   

          var listPedidos = response.data.data;

          if (statusPedidos.length > 0) {
            //console.log(statusPedidos)
            listPedidos = listPedidos.filter(objeto => {
              const statusObjeto = objeto.STATUS;

              // Verificando se o valor do status está presente no array de status desejado
              return statusPedidos.some(
                status => status.value === statusObjeto,
              );
            });
          }
          setDadosPedidos(listPedidos);
          const total = listPedidos
          ? listPedidos.reduce(
              (total, item) => total + (item.PEDIDO_VALOR ? item.PEDIDO_VALOR : 0),
              0
            )
          : 0;
          settotalPedidos(total);

          setLloading(false);
        } catch (err) {
          setLloading(false);
          if (err.response.status === 402) {
            //token expirado
            toast.error('Sua sessão expirou, favor efetuar login');
            sessionStorage.clear();
          } else {
            setDadosPedidos('');
            settotalPedidos(0);
            toast.error('Dados não localizados');
          }
        }
      }
    } catch (error) {
      setLloading(false);
      setDadosPedidos('');
      settotalPedidos(0);

      toast.error('Não localizado, verifique os campos de pesquisa.');
    }
  };

  const rowsList = dadosPedidos
    ? dadosPedidos.map(item => {
        const vendedor_desc = item.VENDEDOR_APELIDO
          ? `${item.VENDEDOR_ID}-${item.VENDEDOR_APELIDO}`
          : item.VENDEDOR_ID;

        const row = createData(
          <div className={classes.badgeContainer}>
            <ModalPedidosSQL data={item} />
            <Pdf num={item.NUMERO_SISTEMA} />
            <SendEmail num={item.NUMERO_SISTEMA} empresa={item.EMPRESA_ID} />
            <NotasOperador num={item.NUMERO_SISTEMA} />
          </div>,
          item.NUMERO_SISTEMA ? item.NUMERO_SISTEMA : '',
          item.EMPRESA,
          item.STATUS == 'PREPARED'
            ? item.WORKFLOW
            : translate(item.STATUS)
            ? translate(item.STATUS)
            : '',
          item.CLIENTE_COD ? item.CLIENTE_COD + ' - ' + item.APELIDO : '',
          moment(item.PEDIDO_EMISSAO.slice(0, 10)).format('DD/MM/YYYY'),
          moment(item.PEDIDO_DISPONIBILIDADE.slice(0, 10)).format('DD/MM/YYYY'),
          item.TAGS ? item.TAGS : '',
          vendedor_desc ? vendedor_desc : '',
          item.PEDIDO_VALOR.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
        );
        return row;

        /*   STATUS_DESCRICAO ? STATUS_DESCRICAO : '',
          CLIENTE_APELIDO ? CLIENTE_APELIDO : '',
          moment(PEDIDO_EMISSAO.slice(0, 10)).format('DD/MM/YYYY'),
          moment(PEDIDO_PREVISAO.slice(0, 10)).format('DD/MM/YYYY'),
          PEDIDO_REFERENCIA,
          PEDIDO_VALOR.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
          <ModalPedidosSQL data={item} />,
          <Pdf num={item.sale.id} />,
          <SendEmail num={item.sale.id} />,
    */
      })
    : [{ error: 'Não encontrado' }];

  return (
    <>
      <Pesquisa>
        <div>
          <ExpansionPanel expanded={PanelOpen}>
            <ExpansionPanelSummary
              expanded={PanelOpen}
              onClick={() => {
                setPanelOpen(!PanelOpen);
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography component={'span'} className={classes.heading}>
                Painel de pesquisa
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <Typography component={'div'}>
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} lg={2}>
                      <div className="input">
                        <label>Número</label>
                        <input type="number" name="numero" ref={register} />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Status</label>
                        <Select
                          name="status"
                          placeholder="Status"
                          styles={{
                            menuPortal: base => ({
                              ...base,
                              zIndex: 22194,
                            }),

                            container: base => ({
                              ...base,
                              minWidth: '8rem',
                            }),
                          }}
                          defaultValue={statusPedidos ? statusPedidos : []}
                          options={status_options}
                          onChange={value => {
                            setStatusPedidos(value);
                          }}
                          isMulti
                        />
                      </div>
                    </Grid>

                    <Grid
                      className="index2"
                      item
                      sm={12}
                      lg={6}
                      md={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <label>Vendedor*</label>

                      <Select
                        options={autoVendedor}
                        isClearable={perfil !== 'vendedor' ? true : false}
                        menuPortalTarget={document.body}
                        isMulti
                        onChange={value => {
                          setIdVendedorP(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Cliente</label>
                        <Async
                          //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                          loadOptions={debouncedLoadOptionsClientes}
                          cacheOptions
                          isClearable={true}
                          menuPortalTarget={document.body}
                          noOptionsMessage={() => 'Nenhuma opção encontrada'}
                          placeholder="Clientes"
                          styles={{
                            menuPortal: base => ({
                              ...base,
                              zIndex: 16,
                            }),
                            container: base => ({
                              ...base,
                              minWidth: '8rem',
                            }),
                          }}
                          value={{
                            label: nomeCliente ? nomeCliente : '',
                            value: idCliente ? idCliente : '',
                          }}
                          onSelect={val => {
                            if (val.length > 1) {
                              setIdCliente(val.value);
                            }
                          }}
                          onChange={value => {
                            const valor = value === null ? '' : value.value;
                            if (valor > 1) {
                              setIdCliente(valor);
                              setNomeCliente(value.labelshow);
                            } else {
                              setIdCliente('');
                              setNomeCliente('');
                            }
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={3}>
                      <div className="input">
                        <label>Data Inicial</label>
                        <input
                          type="date"
                          defaultValue=""
                          name="dataini"
                          ref={register}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={3}>
                      <div className="input">
                        <label>Data Final</label>
                        <input type="date" name="datafim" ref={register} />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Produto Mestre</label>
                        <Async
                          //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                          loadOptions={debouncedLoadOptionsProdutos}
                          cacheOptions
                          isClearable={true}
                          noOptionsMessage={() => 'Nenhuma opção encontrada'}
                          placeholder="Produto"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 223 }),
                            container: base => ({ ...base, minWidth: '20rem' }),
                          }}
                          onChange={value => {
                            const valor = value === null ? '' : value.value;
                            if (valor > 1) {
                              setIdProdutoMestre(valor);
                            } else {
                              setIdProdutoMestre('');
                            }
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Produto Grade</label>
                        <Async
                          //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                          loadOptions={debouncedLoadOptionsProdutosGrade}
                          cacheOptions
                          isClearable={true}
                          noOptionsMessage={() => 'Nenhuma opção encontrada'}
                          placeholder="Produto"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 223 }),
                            container: base => ({ ...base, minWidth: '20rem' }),
                          }}
                          onChange={value => {
                            const valor = value === null ? '' : value.value;
                            if (valor > 1) {
                              setIdProduto(valor);
                            } else {
                              setIdProduto('');
                            }
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Tags</label>
                        <input type="text" name="tags" ref={register} />
                      </div>
                    </Grid>
                  </Grid>
                  <ButtonStyled
                    variant="contained"
                    color="primary"
                    onClick={e => Pesquisaitem(getValues(), e)}
                  >
                    Pesquisar
                  </ButtonStyled>
                </Form>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </Pesquisa>

      <DataTable
        rows={rowsList}
        sort={true}
        rowHead={rowHead}
        title={'Pedidos'}
        titleNoData={'Pesquise os pedidos'}
        load={loading}
        components={{
          Pagination: props => (
            <>
              <Grid container spacing={0} style={{ alignItems: 'end' }}>
                <Grid item xs={10} lg={11} md={11}>
                  <Grid container spacing={0} style={{ width: '100%' }}>
                    <Grid
                      className="index"
                      item
                      sm={12}
                      lg={12}
                      md={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        textAlign: 'end',
                      }}
                    >
                      <Typography variant="subtitle2">Total:</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} lg={1} md={1}>
                  <Grid container spacing={0} style={{ width: '100%' }}>
                    <Grid
                      className="index"
                      item
                      sm={12}
                      lg={12}
                      md={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="subtitle2">
                        {totalPedidos
                          ? totalPedidos.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })
                          : '0,00'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <TablePagination {...props} />
            </>
          ),
        }}
      />
    </>
  );
}

export default connect()(Pedidos);
