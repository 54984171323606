import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import AuthLayout from './layouts/auth';
//const AuthLayout = lazy(() => import('./layouts/auth'));

import DefaultLayout from './layouts/default';
//const DefaultLayout = lazy(() => import('./layouts/default'));

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const signed =
    sessionStorage.getItem('signed') === null
      ? 'false'
      : sessionStorage.getItem('signed');

  const perfil = sessionStorage.getItem('perfil');

  if (signed === 'false' && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed === 'true' && !isPrivate) {
    if (perfil === 'funcionario') {
      return <Redirect to="/dashboard" />;
    } else {
      return <Redirect to="/dashboard" />;
    }
  }

  const Layout = signed ? DefaultLayout : AuthLayout;
  return (
    <Route
      exact
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
